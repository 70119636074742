import React from 'react';
import Home from './Pages/Home';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


function App() {
  return (
    <Home />
  );
}

export default App;